import { defineStore } from 'pinia';
import tabsName from '@Enums/tabsName';

export const useItemTabStore = defineStore('itemTab', {
  state: () => ({
    activeTab: "",
    hideOverflow: false
  }),
  actions: {
    setActiveDetailTab(tabName: string) {
      this.activeTab = tabName;
    },
    hideOverflow(status: boolean) {
      const el = document.getElementsByTagName('html')[0];
      const className = 'overflow-hidden';
      if (status) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
      // this.hideOverflow = status as any;
    },
  },
  getters: {
    getActiveTab: (state) => {
      return state.activeTab;
    },
    // getHideOverflow: (state) => {
    //   return state.hideOverflow;
    // },
  }
});
